<div
  class="modal modal-fx-slideBottom"
  [ngClass]="{ 'is-active': showNoteModal }"
>
  <div class="modal-background" (click)="closeModal()"></div>
  <div class="modal-content">
    <h3 class="modal-title">
      <span *ngIf="editMode">{{ "my-clients.edit-note" | translate }}</span>
      <span *ngIf="!editMode">{{ "my-clients.add-new-note" | translate }}</span>
      <a class="delete" (click)="closeModal()"></a>
    </h3>
    <ng-container *ngIf="isSaving">
      <shared-loading-animation></shared-loading-animation>
    </ng-container>
    <ng-container *ngIf="!isSaving">
      <div class="field">
        <label class="label">{{ "my-clients.title" | translate }}</label>
        <div class="control">
          <input
            type="text"
            placeholder=""
            [(ngModel)]="note.title"
            class="input auth_input is-primary"
            autofocus
          />
        </div>
      </div>
      <div class="field">
        <label class="label">{{ "my-clients.note" | translate }}</label>
        <div class="control">
          <textarea
            name="body"
            placeholder="Your Note"
            id="body"
            cols="30"
            rows="10"
            [(ngModel)]="note.body"
            class="textarea auth_input"
          ></textarea>
        </div>
      </div>
    </ng-container>
    <button
      [disabled]="isSaving"
      [ngClass]="{ 'is-loading': isSaving }"
      (click)="saveNote()"
      class="button is-rounded is-primary"
    >
      {{ "common.save" | translate }}
    </button>
  </div>
</div>
