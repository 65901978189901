import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf, SlicePipe } from '@angular/common';
import { ProfilePictureComponent } from '../../profile-picture/profile-picture.component';
import { TimeAgoPipe } from '../../../../time-ago.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarImageComponent } from '../../avatar-image/avatar-image.component';

@Component({
  selector: 'app-conversation-preview',
  templateUrl: './conversation-preview.component.html',
  styleUrls: ['./conversation-preview.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    ProfilePictureComponent,
    TimeAgoPipe,
    TranslateModule,
    SlicePipe,
    AvatarImageComponent,
  ],
})
export class ConversationPreviewComponent {
  @Input() avatarSrc: string;
  @Input() name: string;
  @Input() message: string;
  @Input() timestamp: Date;
  @Input() unread: boolean = false;
  @Input() showTime: boolean = true;
  @Input() truncateSize: number = 150;
  @Input() showMatchedBadge: boolean = false;
  @Input() flagged: boolean = false;
  @Input() loading: boolean = false;
  @Input() decrypted: boolean = false;

  // New inputs for icons
  @Input() hasAppointment: boolean = false;
  @Input() hasAttachment: boolean = false;
  @Input() hasInvoice: boolean = false;

  @Input() isFlaggedUser: boolean = false;
  @Input() otherUserDeleted: boolean = false;

  @Output() conversationClick = new EventEmitter<void>();

  constructor() {}

  onConversationClick() {
    this.conversationClick.emit();
  }
}
