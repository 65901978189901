/**
 * Wait X ms until performing resolve code block.
 *
 * @param ms Miliseconds to wait.
 *
 */
export const delay = async (ms: number) =>
  await new Promise<void>((resolve) => setTimeout(() => resolve(), ms)).then(
    () => {}
  );
