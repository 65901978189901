import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SortingService {
  constructor() {}

  public sortByLeastRecent(
    array: any[],
    key: string,
    secondKey?: string
  ): any[] {
    return array.sort((a, b) => {
      if (a[key] === null) return 1;
      if (b[key] === null) return -1;
      if (a[key][secondKey] > b[key][secondKey]) return 1;
      if (a[key][secondKey] < b[key][secondKey]) return -1;
      return 0;
    });
  }

  public sortByMostRecent(
    array: any[],
    key: string,
    secondKey?: string
  ): any[] {
    return array.sort((a, b) => {
      if (a[key] === null) return 1;
      if (b[key] === null) return -1;
      if (a[key][secondKey] > b[key][secondKey]) return -1;
      if (a[key][secondKey] < b[key][secondKey]) return 1;
      return 0;
    });
  }

  public sortAlphabetically(array: any[], key: string): any[] {
    return array.sort((a, b) => {
      if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
      if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
      return 0;
    });
  }
}
