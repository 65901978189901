<div class="columns pointer is-mobile conversation" (click)="onConversationClick()">
    <div class="column image-col is-narrow">
        <figure class="conversation-image">
            <div class="image">
                <ng-container *ngIf="decrypted && !loading; else loadingImage">
                    <app-avatar-image
                            [src]="avatarSrc"
                            [alt]="name"
                            [showMatchedBadge]="showMatchedBadge"
                            [showNotification]="unread"
                    ></app-avatar-image>
                </ng-container>
                <ng-template #loadingImage>
                    <div class="loadingblock" style="width: 50px; height: 50px; border-radius: 50%;"></div>
                </ng-template>
            </div>
        </figure>
    </div>
    <div class="column is-right-in-card">
        <div class="columns is-multiline is-vcentered is-mobile">
            <div class="column oneline conversation-name pl-0">
                <ng-container *ngIf="!isFlaggedUser && decrypted && !loading; else loadingName">
                    {{ name }}
                    <span *ngIf="otherUserDeleted">(deleted)</span>
                </ng-container>
                <ng-container *ngIf="isFlaggedUser">
                    <em>{{ 'messaging.flagged-user' | translate }}</em>
                </ng-container>
                <ng-template #loadingName>
                    <span class="loadingblock" style="width: 200px; height: 14px;">&nbsp;</span>
                </ng-template>
            </div>
            <div class="column oneline message-time-ago pl-0 pr-0 is-narrow">
                <ng-container *ngIf="decrypted && !loading; else loadingTime">
                    <div>{{ timestamp | timeAgo }}</div>
                </ng-container>
                <ng-template #loadingTime>
                    <span class="loadingblock" style="width: 80px; height: 14px;">&nbsp;</span>
                </ng-template>
            </div>
            <div class="column oneline pl-0 is-narrow is-arrow">
                <div *ngIf="decrypted && !loading">
                    <i class="material-icons">chevron_right</i>
                </div>
            </div>
            <div class="column is-12 pt-0 pl-0">
                <ng-container *ngIf="decrypted && !loading; else loadingMessage">
                    <p class="conversation-message">
                        <span *ngIf="hasAppointment"><i class="icon icon-calendar"></i></span>
                        <span *ngIf="hasAttachment"><i class="icon icon-attachment"></i></span>
                        <span *ngIf="hasInvoice"><i class="icon icon-invoice"></i> {{ 'common.invoice' | translate }}</span>
                        <span *ngIf="message.length > truncateSize">{{ message | slice : 0 : truncateSize }}...</span>
                        <span *ngIf="message.length <= truncateSize">{{ message }}</span>
                    </p>
                </ng-container>
                <ng-template #loadingMessage>
                    <span class="loadingblock" style="width: 70%; height: 14px;">&nbsp;</span>
                </ng-template>
            </div>
        </div>
    </div>
</div>
