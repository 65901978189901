import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Client } from '../../../entities/client.model';
import { Note } from '../../../entities/note.model';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { map } from 'rxjs/operators';
import { LeanConversationUser } from '../../../entities/lean-conversation-user.model';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(private http: GenericHttpService) {}

  getClients = (): Observable<Client[]> => {
    return this.http.get('/clients').pipe(
      map((response) => {
        if (response.data.length == 0) {
          return [];
        }
        return response.data.map((client) => client.attributes);
      })
    );
  };

  getArchivedClients = (): Observable<Client[]> => {
    return this.http.get('/archived_clients').pipe(
      map((response) => {
        if (response.data.length == 0) {
          return [];
        }
        return response.data.map((client) => client.attributes);
      })
    );
  };

  getClient = (client_id): Observable<Client> => {
    return this.http.get('/clients/' + client_id).pipe(
      map((response) => {
        if (!response.data) {
          return [];
        }
        return response.data.attributes;
      })
    );
  };

  getNotes = (client_id): Observable<Note[]> =>
    this.http.get('/notes_by_client/' + client_id);

  sendNote = (note: Note): Observable<Note> => this.http.post('/notes', note);

  updateNote = (note: Note): Observable<Note> =>
    this.http.put(`/notes/${note.id}`, note);

  deleteNote = (note: Note): Observable<any> =>
    this.http.delete('/notes/' + note.id);

  archiveClient = (id: number, value: boolean): Observable<any> =>
    this.http.put(`/archive_client/${id}`, { value: value });

  reportClient(
    user: LeanConversationUser,
    description: string,
    messages: any
  ): Observable<any> {
    const payload = {
      description,
      messages,
    };
    return this.http.post(`/clients/${user.id}/report`, payload);
  }
}
