import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Note } from '../../../entities/note.model';
import { Conversation } from '../../../entities/conversation.model';
import { ClientsService } from '../service/clients.service';
import { CryptoService } from '../../messaging/service/crypto.service';
import { MessagingService } from '../../messaging/service/messaging.service';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { LoadingAnimationComponent } from '../../../shared/components/loading-animation/loading-animation.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    LoadingAnimationComponent,
    FormsModule,
    TranslateModule,
  ],
})
export class NoteModalComponent implements OnInit {
  constructor(
    private clientsService: ClientsService,
    private crytoService: CryptoService,
    private messagingService: MessagingService,
    private alertService: AlertService
  ) {}

  @Input()
  public note: Note;

  public showNoteModal: boolean;
  public isSaving: boolean = false;

  public editMode: boolean = false;

  ngOnInit() {
    this.showNoteModal = true;
    if (this.note) {
      this.editMode = true;
    } else {
      this.note = new Note();
    }
  }

  @Input()
  client_id: number;

  @Input()
  conversation: Conversation;

  @Output()
  modalState = new EventEmitter<boolean>();

  @Output()
  updateNotes = new EventEmitter();

  saveNote() {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.assertConversation(this.client_id).then(() => {
      let date = new Date();
      date.setHours(date.getUTCHours()); // set to UTC hours so that the date will be correct across timezones
      // this.note.updated_at = date;
      this.note.client_id = this.client_id;
      this.crytoService
        .encryptNote(this.note, this.conversation)
        .then((note) => {
          let sendFn = this.editMode ? 'updateNote' : 'sendNote';
          this.clientsService[sendFn](note).subscribe((res) => {
            this.updateNotes.next(true);
            this.closeModal();
            this.alertService.success('Note saved');
            this.isSaving = false;
          });
        });
    });
  }

  closeModal() {
    this.note = null;
    this.modalState.next(false);
  }

  assertConversation(id) {
    return new Promise<void>((resolve, reject) => {
      if (!this.conversation) {
        this.messagingService.getConversation(id).subscribe((conversation) => {
          conversation = conversation['data'].attributes;
          this.conversation = conversation;
          resolve();
        });
      } else {
        resolve();
      }
    });
  }
}
