<app-conversation-preview
  [avatarSrc]="avatarSrc"
  [name]="title"
  [message]="latestMessageTextWithTruncation"
  [timestamp]="latestMessageTime"
  [unread]="isUnread"
  [showTime]="showTime"
  [truncateSize]="truncateSize"
  [showMatchedBadge]="showMatchedBadge"
  [flagged]="false"
  [otherUserDeleted]="otherUserIsDeactivated"
  [decrypted]="true"
  [loading]="false"
  [hasAppointment]="latestMessageHasAppointment"
  [hasAttachment]="latestMessageHasGenericAttachment"
  [hasInvoice]="latestMessageHasInvoice"
  (conversationClick)="onConversationClick()"
></app-conversation-preview>

