import { DefaultStreamChatGenerics } from 'stream-chat-angular';
import { Channel } from 'stream-chat';
import { DefaultUserType } from 'stream-chat-angular/lib/types';
import { User } from '../../entities/user.model';

function getOtherMemberIfOneToOneChannel(
  currentUser: User,
  channel: Channel<DefaultStreamChatGenerics>
): DefaultUserType | undefined {
  return getOtherMemberIfOneToOneChannelById(
    currentUser.external_id.toString(), // currently we re-purpose user ids as GS ids
    channel
  );
}

function getOtherMemberIfOneToOneChannelById(
  myUserId: string, // note that thes eshould be GetStream User ids
  channel: Channel<DefaultStreamChatGenerics>
): DefaultUserType | undefined {
  const otherMembers = Object.values(channel?.state?.members || {}).filter(
    (m) => m && m.user_id && m.user_id !== myUserId
  );
  if (otherMembers.length === 1) {
    return otherMembers[0].user;
  } else {
    return undefined;
  }
}

export { getOtherMemberIfOneToOneChannel, getOtherMemberIfOneToOneChannelById };
